import {createMuiTheme} from '@material-ui/core'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#7099DD'
        },
        secondary: {
            main: '#DD70D0'
        },
        info: {
            main: '#f0dac9'
        }
    },
    typography: {
        fontFamily: [
            'Open Sans',
            'sans-serif',
        ].join(','),
    }
})

export default theme